/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
try {
    window._ = require('lodash')
    window.Popper = require('@popperjs/core').default

    window.jQuery = require('jquery')
    window.$ = window.jQuery
    global.jQuery = window.jQuery
    global.$ = window.jQuery
    if (
        typeof navigator !== 'undefined' &&
        typeof navigator.userAgent !== 'undefined'
    ) {
        $.browser.device =
            /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
                navigator.userAgent.toLowerCase()
            )
    }
} catch (e) {
    //
}
